/* my-profile.css */

/* Main Container */
.my-profile {
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

/* Headings */
.my-profile h2 {
    color: #333;
    margin-bottom: 20px;
}

/* Profile Section */
.myprofile-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

/* Profile Item */
.profile-item {
    flex: 1 1 calc(50% - 20px);
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.profile-item label {
    font-weight: bold;
    color: #50cada;
    margin-bottom: 5px;
}

.profile-item input[type="text"],
.profile-item input[type="email"],
.profile-item input[type="file"],
.profile-item input[type="date"],
.profile-item textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

.profile-item textarea {
    resize: vertical;
    height: 80px;
}

.profile-item span {
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
}

.profile-item img.clinic-logo,
.logo-preview {
    max-width: 150px;
    height: auto;
    border-radius: 4px;
    margin-top: 10px;
}

/* Profile Actions */
.profile-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
}

.profile-actions button {
    background-color: #50cada;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.day-time-selection {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.day-time-selection label {
    margin-right: 20px;
    font-weight: bold;
    color: #333;
}

.time-selection {
    display: flex;
    align-items: center;
}

.time-selection input[type="time"] {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .myprofile-section {
        flex-direction: column;
    }

    .profile-item {
        flex: 1 1 100%;
    }
}
