.signup-page {
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: linear-gradient(45deg, #179bad, #12e4ff);
  padding-top: 10vh; /* 10vh gap from the top */
}

.signup-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Logo adjustments */
.signup-logo {
  display: block;
  margin: 0 auto 20px;
  max-width: 100px; /* Ensure it scales properly */
}

/* Form text center */
.signup-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.signup-container form {
  display: flex;
  flex-direction: column;
}

.signup-container label {
  margin-bottom: 10px;
  font-weight: bold;
}

.signup-container input {
  width: 96%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signup-container button {
  padding: 10px;
  background-color: #50cada;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.signup-container button:hover {
  background-color: #179bad;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .signup-container {
    padding: 15px;
  }
}
