.manage-practitioners {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.manage-practitioners h2 {
    color: black;
    margin-bottom: 20px;
}

.modal-actions {
    display: flex;
}

.add-practitioner-button {
    background-color: #50cada;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
}

.add-practitioner-button:hover {
    background-color: #3c9aa7;
}

.practitioner-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
    overflow-y: auto; /* Allow the modal content to scroll */
}

.practitioner-modal-content {
    background: linear-gradient(45deg, #40a1ad, #57e1f3);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    width: 80%; /* Default width for large screens */
    max-width: 600px; /* Maximum width of the modal */
    height: auto; /* Automatically adjust height */
    animation: slideIn 0.3s ease-in-out;
    color: white;
    overflow-y: auto; /* Enable scrolling if content overflows */
}

.practitioner-modal-content select {
    width: calc(100% - -2px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #767676;
    background-color: white;
}

.practitioner-modal-content h3 {
    color: white !important;
    margin-bottom: 15px;
    text-align: center;
}

.practitioner-modal-content input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #767676;
    background-color: white;
}

.practitioner-modal-content button {
    background-color: #50cada;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    height: fit-content;
    flex-direction: row;
    margin: 10px;
}

.practitioner-modal-content button:hover {
    background-color: #3c9aa7;
}

.practitioner-details-container label {
    font-weight: bold;
    background-color: white;
    color: #50cada;
    padding: 5px;
    border-radius: 5px;
}

.practitioner-details-container p {
    text-align: center;
    font-weight: bold;
    border: 1px solid white;
    border-radius: 5px;
    padding: 3px;
    height: 20px;
}

.practitioner-details-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 2px;
    margin-bottom: 20px;
    align-items: center;
}


.close-button {
    background-color: #ccc;
    color: #333;
}

.close-button:hover {
    background-color: #bbb;
}

.practitioners-list {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.practitioners-list h3 {
    color: #50cada;
    margin-bottom: 15px;
}

.practitioners-list table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
}

.practitioners-list table th, 
.practitioners-list table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    cursor: pointer; /* Make table rows clickable */
}

.practitioners-list table th:first-child {
    border-top-left-radius: 8px;
}

.practitioners-list table th:last-child {
    border-top-right-radius: 8px;
}

.practitioners-list table td:first-child {
    border-bottom-left-radius: 8px;
}

.practitioners-list table td:last-child {
    border-bottom-right-radius: 8px;
}

.practitioners-list table th {
    background-color: #e0f0ff;
    color: #50cada;
}

.practitioners-list table td {
    background-color: #f9f9f9;
}

.practitioners-list button {
    background-color: #28a745;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 5px;
}

.practitioners-list button:hover {
    background-color: #218838;
}

.practitioners-list button:last-child {
    background-color: #dc3545;
}

.practitioners-list button:last-child:hover {
    background-color: #c82333;
}

.bottom-section-edit-profile {
    display: flex;
    align-items: center;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* Profile Picture in Modal */
.profile-edit-picture-preview {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%; /* Optional, circular image preview */
    margin-bottom: 20px;
}

.profile-picture-large {
    width: 130px;
    height: 130px;
    object-fit: cover; /* This keeps the aspect ratio intact while filling the 250x250 box */
    border-radius: 50%; /* Optional, if you want the image to be circular */
    margin-left: 40%;   
}

.profile-picture-div {
    text-align-last: center;
}

.stripe-modal-content {
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    width: 400px;
    max-width: 90%;
    animation: slideIn 0.3s ease-in-out;
    text-align: center;
    padding: 25px;
    background: linear-gradient(45deg, #50cada, #50cada);
}

.stripe-modal-content button {
    background-color: white;
    color: #50cada;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 25px;
    transition: 0.5s;
    margin-top: 20px;
    border: solid transparent 1px;
}

.stripe-modal-content button:hover {
    background-color: #50cada;
    transition: 0.3s;
    color: white;
    border: solid white 1px;
}

.stripe-modal-content h3 {
    color: white;
    margin-bottom: 15px;
}

.stripe-modal-content input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #767676;
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideIn {
    from { transform: translateY(-20px); }
    to { transform: translateY(0); }
}

/* Payment Button in Modal */
.modal-content .payment-button {
    background-color: #ffc107;
    color: #fff;
}

.modal-content .payment-button:hover {
    background-color: #e0a800;
}

/* Make the modal responsive for different screen sizes */

@media screen and (max-width: 768px) {
    .practitioner-modal-content {
        width: 90%; /* Take up more width on smaller screens */
        max-width: 90%; /* Ensure it doesn’t exceed screen width */
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .practitioner-details-container {
        grid-template-columns: 1fr; /* Single column on smaller screens */
        gap: 10px;
    }

    .profile-picture-large {
        width: 100px; /* Reduce the profile picture size for smaller screens */
        height: 100px;
    }
}

/* For mid-sized screens (like tablets) */
@media screen and (max-width: 1024px) {
    .practitioner-modal-content {
        width: 85%;
        max-width: 90%;
        padding: 20px;
    }

    .practitioner-details-container {
        grid-template-columns: 1fr 1fr; /* Keep two columns but make them responsive */
        gap: 10px;
    }
}

/* For larger screens (like desktops) */
@media screen and (min-width: 1280px) {
    .practitioner-modal-content {
        width: 45%;
        max-width: 570px;
    }
}

/* For very large screens */
@media screen and (min-width: 1600px) {
    .practitioner-modal-content {
        width: 50%;
        max-width: 650px;
        justify-content: center;
    }
}

.add-practitioner-buttons {
    display: flex;
}