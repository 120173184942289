/* General styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
}

/* Main Billing Page Container */
.billing-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Headings */
.billing-page h2 {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
}

.billing-page h3 {
    color: #50cada;
    margin-bottom: 20px;
    font-size: 24px;
    border-bottom: 2px solid #50cada;
    padding-bottom: 10px;
}

/* Payment Methods Section */
.payment-methods {
    margin-bottom: 40px;
}

.payment-methods ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.payment-method-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
}

.payment-method-item:hover {
    background-color: #e0e0e0;
}

.payment-method-item span {
    font-size: 16px;
    color: #333;
}

/* Remove Button */
.remove-btn {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.remove-btn:hover {
    background-color: #d42d2e;
}

/* Add Payment Method Section */
.add-payment-method {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.add-payment-method input,
.add-payment-method select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 300px;
    transition: border-color 0.3s ease;
}

.add-payment-method input:focus,
.add-payment-method select:focus {
    border-color: #50cada;
}

.add-btn {
    background-color: #50cada;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-btn:hover {
    background-color: #3c9fac;
}

/* Transactions History Section */
.transactions-history {
    margin-top: 40px;
}

.transactions-history table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    background-color: white;
    border-radius: 10px;
}

.transactions-history th,
.transactions-history td {
    text-align: left;
    padding: 15px;
    font-size: 16px;
}

.transactions-history th {
    background-color: #50cada;
    color: white;
    font-weight: 600;
}

.transactions-history td {
    border-bottom: 1px solid #f0f0f0;
}

.transactions-history tr:last-child td {
    border-bottom: none;
}

.transactions-history tr:hover {
    background-color: #f5f5f5;
}

.transactions-history td {
    color: #333;
}

/* Transaction Amount Styling */
.transactions-history td:nth-child(4) {
    font-weight: bold;
    color: #50cada;
}

.transactions-history button {
    padding: 10px;
    background-color: #50cada;
    color: white;
    border: transparent;
    border-radius: 5px;
    transition: 0.5s;
    cursor: pointer;
}

.transactions-history button:hover {
    padding: 10px;
    background-color: #3c9fac;
    color: white;
    border: transparent;
    border-radius: 5px;
    transition: 0.5s;
    cursor: pointer;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .billing-page {
        padding: 10px;
    }

    .add-payment-method {
        flex-direction: column;
        align-items: flex-start;
    }

    .add-payment-method input,
    .add-payment-method select {
        width: 100%;
    }

    .add-btn {
        width: 100%;
    }
}

/* Modal Overlay to cover the entire screen */
.stripe-modal-overlay {
    position: fixed; /* Fixed positioning so the modal is always in view */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 9999; /* High z-index to make sure it's on top of everything */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Content Box */
.stripe-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    width: 400px;
    max-width: 90%; /* Ensures it fits smaller screens */
    position: relative;
    z-index: 10000; /* Higher than the overlay */
}

/* Close Button */
.close-btn {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-btn:hover {
    background-color: #d42d2e;
}

/* Submit Button for Stripe */
.submit-btn {
    background-color: #50cada;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    width: 100%; /* Full width button */
}

.submit-btn:hover {
    background-color: #3c9fac;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .stripe-modal-content {
        width: 90%;
    }
}
