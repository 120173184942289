/* src/login.css */

.login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #179bad, #12e4ff);
    flex-direction: column;
  }
  
  .login-logo {
    padding: 25px;
  }

  .login-form-logo {
    scale: 0.2;
    margin: -215px -100px;
  }

  .login-form {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    margin-bottom: 135px;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
    font-weight: bold;
  }
  
  .form-group input {
    width: 92%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .error {
    color: red;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #50cada;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: 0.5s;
  }
  
  .login-button:hover {
    background-color: #3c9aa7;
  }
  
  .sign-up-text {
    text-align: center;
    color:#555
  }

  .signup-link {
    color: #179bad;
    text-decoration: none;
    font-weight: bold;
    transition: 0.5s;
    padding: 5px;
    border-radius: 8px;
  }

  .signup-link:hover {
    color: #f4feff;
    text-decoration: none;
    font-weight: bold;
    background-color: #179bad;
    padding: 5px;
    border-radius: 8px;
  }