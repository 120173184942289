/* Main Container */
.appointments-all {
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

/* Headings */
.appointments-all h2 {
    color: #333;
    margin-bottom: 20px;
}

.appointments-all h3 {
    color: #50cada;
    margin-bottom: 15px;
}

/* Filters Section */
.appointments-filters {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.appointments-filters input, .appointments-filters select {
    width: calc(33% - 15px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #f5f5f5;
    color: #333;
}

.appointments-filters button {
    background-color: #50cada;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.appointments-filters button:hover {
    background-color: #3c9aa7;
}

/* Appointment List */
.appointments-list {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.appointments-list table {
    width: 100%;
    border-collapse: collapse;
}

.appointments-list th, .appointments-list td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.appointments-list th {
    background-color: #50cada;
    color: white;
}

.appointments-list tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

/* Advanced Appointment Modal */
.appointments-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.appointments-modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    width: 600px;
    max-width: 90%;
}

.appointments-modal-content h4 {
    margin-bottom: 15px;
}

.appointments-modal-content button {
    background-color: #50cada;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
    margin-bottom: 5px;
}

.appointments-modal-content button:hover {
    background-color: #3c9aa7;
    margin-bottom: 5px;
}

.appointments-details-options button.reschedule-btn {
    background-color: #f5a623;
    margin-bottom: 5px;
}

.appointments-details-options button.cancel-btn {
    background-color: #ff6347;
    margin-bottom: 5px;
}

.appointments-modal-content button.close-btn {
    background-color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
    .appointments-filters input, .appointments-filters select {
        width: 100%;
        margin-bottom: 10px;
    }

    .appointments-modal-content {
        width: 100%;
    }
}
