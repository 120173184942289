

.topbar {
    width: calc(100% - 19px);
    height: 60px;
    background-color: #50cada;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .profile-section {
    position: relative;
  }
  
  .profile-icon img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    width: 150px;
  }
  
  .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-menu ul li {
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .dropdown-menu ul li a {
    text-decoration: none;
    color: #333;
    display: block;
  }
  
  .dropdown-menu ul li:hover {
    background-color: #f0f0f0;
  }
  
  .layout {
    display: flex;
    height: 100vh;
  }
  
  .main-content {
    margin-left: 250px; /* Space for sidebar */
    width: calc(100% - 250px);
    padding-top: 60px; /* Space for topbar */
    background-color: #f5f5f5;
    overflow-y: auto;
  }
  
  .content {
    padding: 20px;
  }