.otp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(45deg, #179bad, #12e4ff);
}

.otp-logo {
    margin-bottom: 20px;
}

.otp-form {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 320px;
}

.otp-form-logo {
    width: 80px;
    margin-bottom: 20px;
}

.otp-h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.otp-form-group {
    margin-bottom: 20px;
    text-align: left;
}

.otp-form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #50cada;
}

.otp-form-group input {
    width: 93%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.otp-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #50cada;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.otp-button:hover {
    background-color: #3c9aa7;
}

.error {
    color: red;
    margin-bottom: 15px;
}
