.sidebar {
  width: 250px;
  background-color: #50cada;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1000; /* Ensure sidebar is on top */
}

.toplogo {
  height: 35px;
}

.logo {
  text-align: center;
}

.nav-menu ul {
  list-style: none;
  padding-left: 15px;
  font-weight: 500;
  padding-right: 10px;
}

.nav-menu ul li {
  margin: 10px 0;
}

.nav-menu ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 10px;
  transition: background-color 0.2s;
}

.nav-menu ul li a svg {
  margin-right: 10px; /* Adds space between the icon and text */
}

.nav-menu ul li a:hover, .nav-menu ul li a.active {
  background-color: #3ca2aa;
  border-radius: 4px;
}
