/* Main Container */
.revenue-generation {
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

/* Headings */
.revenue-generation h2 {
    color: #333;
    margin-bottom: 20px;
}

/* Filters Section */
.revenue-filters {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.revenue-filters input, .revenue-filters select {
    width: calc(25% - 10px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #f5f5f5;
    color: #333;
}

.revenue-filters button {
    background-color: #50cada;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.revenue-filters button:hover {
    background-color: #3c9aa7;
}

.revenue-filters .export-btn {
    background-color: #50cada;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.5s;
}

.revenue-filters .export-btn:hover {
    background-color: #34818b;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
}

/* Advanced Total Revenue and Commission Card */
.totals-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.revenue-section, .commission-section {
    display: flex;
    align-items: center;
}

.revenue-details h3, .commission-details h3 {
    margin: 0;
    font-size: 22px;
    color: #333;
    display: flex;
    align-items: center;
}

.revenue-details p, .commission-details p {
    font-size: 26px;
    color: #333;
    margin-top: 5px;
    font-weight: bold;
}

.font-awesomeeicon {
    overflow: visible;
    box-sizing: content-box;
    color: white;
    font-size: 45px;
    background-color: #50cada;
    padding: 10px;
    border-radius: 15px;
    margin: 10px;
}

/* Style for icons */
.revenue-icon, .commission-icon {
    font-size: 36px; /* Larger font size */
    margin-right: 15px;
    color: #50cada;  /* Use the theme color for consistency */
    display: inline-block;
    vertical-align: middle; /* Align icon with text */
}

/* Revenue Table */
.revenue-table {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.revenue-table table {
    width: 100%;
    border-collapse: collapse;
}

.revenue-table th, .revenue-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.revenue-table th {
    background-color: #50cada;
    color: white;
}

.revenue-table tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

.revenue-table button {
    padding: 10px;
    background-color: #50cada;
    color: white;
    border-radius: 5px;
    border: transparent;
    transition: 0.5s;
}

.revenue-table button:hover {
    padding: 10px;
    background-color: #2f7983;
    color: white;
    border-radius: 5px;
    border: transparent;
}

/* Payment Modal */
.revenue-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.revenue-modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
}

.revenue-modal-content input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 14px;
    background-color: #f5f5f5;
    color: #333;
}

.revenue-modal-content button {
    background-color: #50cada;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.revenue-modal-content button:hover {
    background-color: #3c9aa7;
}

.revenue-modal-content .close-btn {
    background-color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
    .revenue-filters input, .revenue-filters select {
        width: 100%;
        margin-bottom: 10px;
    }

    .total-revenue-card {
        flex-direction: column;
        align-items: flex-start;
    }

    .revenue-summary, .commission-summary {
        margin-bottom: 15px;
    }

    .revenue-modal-content {
        width: 100%;
    }
}
