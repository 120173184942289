/* Main Container */
.subscriptions {
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

/* Headings */
.subscriptions h2 {
    color: #333;
    margin-bottom: 20px;
}

.subscriptions h3 {
    color: #50cada;
    margin-bottom: 15px;
}

/* Current Subscription Section */
.current-subscription {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.subscription-detail {
    flex: 1 1 calc(50% - 20px);
    margin-bottom: 15px;
}

.subscription-detail label {
    font-weight: bold;
    color: #50cada;
    margin-bottom: 5px;
    display: block;
}

.subscription-detail input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    background-color: #f5f5f5;
    color: #333;
}

.subscription-detail input:disabled {
    background-color: #f5f5f5;
    color: #333;
}

/* Available Packages Section */
.available-packages {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.package-card {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    box-sizing: border-box;
}

.package-card h4 {
    color: #333;
    margin-bottom: 10px;
}

.package-card button {
    background-color: #50cada;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.package-card button:hover {
    background-color: #3c9aa7;
}

/* Responsive Design */
@media (max-width: 768px) {
    .subscriptions {
        padding: 10px;
    }

    .current-subscription,
    .available-packages,
    .package-card {
        padding: 15px;
    }

    .subscription-detail {
        flex: 1 1 100%;
    }

    .package-card {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .package-card button {
        padding: 8px 15px;
    }
}