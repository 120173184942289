.otp-screen {
    background: linear-gradient(45deg, #50a2ad, #50cada);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp-box {
    background-color: white;
    padding: 25px;
    border-radius: 15px;
    text-align: center;
    width: 50vh;
}

.signup-otp-form input {
    padding: 5px;
}

.submit-otp-btn {
    padding: 10px;
    margin: 10px;
    background-color: #50cada;
    border-radius: 5px;
    border: none;
    color: white;
    transition: 0.3s;
}

.submit-otp-btn:hover {
    background-color: #50a2ad;
    cursor: pointer;
}