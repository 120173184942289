/* Overlay for the modal */
.add-clinic-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;

  }
  
  /* Modal content */
  .add-clinic-modal-content {
    margin-block: 3%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin-top: 290px;
    height: auto;
    width: 100%;
    /* position: relative; */
    overflow-y: auto;
  }
  
  /* Modal header */
  .add-clinic-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .add-clinic-close-btn {
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Modal body */
  .add-clinic-modal-body {
    margin-top: 15px;
  }
  
  .add-clinic-form-group {
    margin-bottom: 15px;
  }
  
  .add-clinic-form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .add-clinic-form-input {
    width:97%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-clinic-map-container {
    height: 300px;
    margin-top: 20px;
  }
  
  /* Modal footer */
  .add-clinic-modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .add-clinic-cancel-btn,
  .add-clinic-save-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-clinic-cancel-btn {
    background-color: #ccc;
    margin-right: 10px;
  }
  
  .add-clinic-save-btn {
    background-color: #50cada;
    color: white;
  }
  
  .add-clinic-form-select {
    width:100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }