/* manage-patients.css */

/* Main Container */
.manage-patients {
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

/* Headings */
.manage-patients h2,
.patients-list h3 {
    color: #333;
    margin-bottom: 20px;
}

.modal-content h3 {
    color: #50cada;
    margin-bottom: 15px;
}

/* Add Patient Button */
.add-patient-button {
    background-color: #50cada;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
}

.add-patient-button:hover {
    background-color: #3c9aa7;
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

/* Modal Content */
.modal-content {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    width: 500px;
    max-width: 90%;
    animation: slideIn 0.3s ease-in-out;
    position: relative;
}

.modal-content input,
.modal-content textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

.modal-content textarea {
    resize: vertical;
    height: 80px;
}

.StripeElement-submit-btn button {
    background-color: #50cada;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

.StripeElement-submit-btn button:hover {
    background-color: #3c9aa7;
}

.modal-content .close-button {
    background-color: #ccc;
    color: #333;
}

.modal-content .close-button:hover {
    background-color: #bbb;
}

/* Delete Confirmation Modal Specific Styles */
.modal-content p {
    margin-bottom: 20px;
    color: #333;
}

.StripeElement-submit-btn button:last-child {
    margin-right: 0;
}

/* Patients List */
.patients-list {
    margin-top: 30px;
}

.patients-list table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
}

.patients-list th,
.patients-list td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
}

.patients-list th {
    background-color: #50cada;
    color: #fff;
}

.patients-list tr:nth-child(even) {
    background-color: #f2f2f2;
}

.patients-list tr:hover {
    background-color: #e9e9e9;
}

/* Action Buttons within Table */
.patients-list button {
    background-color: #50cada;
    color: #fff;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 5px;
    transition: background-color 0.3s ease;
}

.patients-list button:hover {
    background-color: #3c9aa7;
}

.patients-list button:last-child {
    margin-right: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .modal-content {
        width: 90%;
    }

    .patients-list table,
    .patients-list thead,
    .patients-list tbody,
    .patients-list th,
    .patients-list td,
    .patients-list tr {
        display: block;
    }

    .patients-list tr {
        margin-bottom: 15px;
    }

    .patients-list th {
        display: none;
    }

    .patients-list td {
        position: relative;
        padding-left: 50%;
        border: none;
        border-bottom: 1px solid #ddd;
    }

    .patients-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 15px;
        font-weight: bold;
        white-space: nowrap;
    }
}

.gender-select {
    text-align: center;
    padding: 5px;
    background-color: #50cada;
    color: white;
    border: solid white 1px;
    border-radius: 5px;
    margin-bottom: 15px;
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

/* Profile Picture Preview */
.profile-picture-preview {
    margin-top: 10px;
    max-width: 100%;
    height: auto;
    border-radius: 50%;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modal-content button {
    margin: 10px;
    padding: 10px;
    background-color: #50cada;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-content button:hover {
    background-color: #3c9aa7;
}
