.btn-primary {
    padding: 10px;
    background-color: #50cada;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.btn-primary:hover {
    background-color: #3c9aa7;
}