.topbar {
    width: 100%;
    height: 60px;
    background-color: #50cada;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 900; /* Ensure topbar is below the sidebar */
  }
  
  .profile-section {
    position: relative;
  }
  
  .profile-icon img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }

  .profile-icon svg {
    color: white;
    transition: 0.4s;
    padding: 5px;
  }

  .profile-icon svg:hover {
    color: #50cada;
    border-radius: 5px;
    background-color: white;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    width: 150px;
  }
  
  .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-menu ul li {
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .dropdown-menu ul li a {
    text-decoration: none;
    color: #333;
    display: block;
  }
  
  .dropdown-menu ul li:hover {
    background-color: #f0f0f0;
  }

/* Scoped CSS to the 'help-faq-page' */
.help-faq-page .specific-icon {
  font-size: 38px;
  color: white;
  margin-right: 10px;
  margin-bottom: 4px;
  transition: color 0.3s ease, transform 0.3s ease;
  padding: 5px;
}

.help-faq-page .specific-icon:hover {
  color: #50cada;
  background-color: white;
  border-radius: 5px;
}
